@use "../../../variables";

.my-workspace-container {
	gap: 8px;
	padding: 15px 15px 15px 15px;
	border-radius: 4px;
}

.workspace-h1 {
	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
	margin-left: 4px;
	font-family: variables.$primary-font;
	text-align: left;
}

.my-workspace {
	width: 200px;
}
