.assistant {
	display: flex;
	width: fit-content;
	max-width: calc(100% - 12px);
	border-radius: 24px;
	background: linear-gradient(
		90deg,
		rgba(213, 189, 106, 0.3) 0%,
		rgba(111, 98, 55, 0.3) 100%
	);
	color: #e3e3e3;
	padding: 8px 16px;

	align-self: flex-start;
	word-break: break-word;
	white-space: break-spaces;
	transition:
		opacity 0.5s ease-in,
		transform 0.5s ease-in;
}
.user {
	display: flex;
	width: fit-content;
	max-width: calc(100% - 12px);
	border-radius: 24px;
	background-color: #282828;
	padding: 8px 16px;
	color: #fff;
	align-self: flex-end;
	word-break: break-word;
	white-space: break-spaces;
	box-shadow:
		0 0 0 1px #d5bd6a,
		0 0 0 1px #6f6237;
}
.code-block {
	width: 100%;
	max-height: none;
	overflow: visible !important;
}
.code-block-container {
	position: relative;
	background-color: #2d2d2d;
	border-radius: 5px;
	margin-left: 50px;
}

.code-block-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #44475a;
	color: #f8f8f2;
	padding: 5px 10px;
}

.code-language {
	font-size: 0.85em;
}

.copy-button {
	background: none;
	border: none;
	color: #50fa7b;
	cursor: pointer;
	font-size: 0.85em;
}

.copy-button:hover {
	text-decoration: underline;
}

.code-block {
	padding: 10px;
}
