.css-1nmdiq5-menu{
	max-width : 180px !important;
    overflow-y: auto; /* Allows vertical scrolling if needed */
	overflow-x: hidden; /* Prevents horizontal scrolling */
    max-height: 150px;
    /* position: relative !important; */
}
.css-1n6sfyn-MenuList{
  overflow: hidden !important;
}
input::-webkit-input-placeholder{
    color: #8c95a8 !important;
}

/* .css-1n6sfyn-MenuList {
    position: absolute !important;
} */