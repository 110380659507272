@use "../../variables";
.bug-container {
	max-width: min(25.4vw, 380px);
	// min-width: 100%;
	max-height: 300px;
	border-radius: 6px;
	border: 1px solid variables.$secondary-color;
	position: relative;
}

.bug-image-container {
	height: 254px;
	border-radius: 6px;
}

.bug-reporter {
	background: variables.$bug-reporter-avatar-bg-color;
	color: variables.$primary-font-color;
	width: 45px;
	height: 45px;
	border-radius: 50%;
	position: absolute;
	top: 20px;
	left: 20px;

	font-size: 30px;
	font-weight: 500;
	line-height: 41px;
	letter-spacing: 0px;
}

.bug-link {
	width: min(7.5vw, 60px);
	height: 41px;
	background-color: variables.$bug-link-bg-color;
	border-radius: 24px;
	position: absolute;
	right: 20px;
	top: 20px;
	scale: 0.8;
}
.bug-link img {
}

.play-icon {
	position: absolute;
	display: flex;
	left: 50%;
	top: 40%;
	transform: translate(
		-50%,
		-50%
	); /* Center the div both horizontally and vertically */
	background: black;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	justify-content: center;
	align-items: center;
}

.description {
	font-size: 16px;
	font-weight: 600;
	line-height: 27px;
	letter-spacing: 0em;
	text-align: left;
	margin: 0px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.image {
	max-height: 200px !important;
	min-height: 200px;
	min-width: 100%;
	border: 1px solif red !important;
}
