@use "../../variables";
.create-workspace-button {
	width: 169px;
	height: 32px;
	border-radius: 6px;
	gap: 4px;
	background-color: variables.$secondary-color;
	margin-top: 1.5rem;
	color: variables.$primary-font-color;
	cursor: pointer;
	margin-right: 1rem;
	border: none;
}
.go-back-btn {
	border: none;
	background-color: variables.$primary-font-color;
	cursor: pointer;
}

.modal-content {
	height: 237px !important;
}

.modal-body {
	padding: 20px !important;
}

.tb-modal-heading {
	font-weight: 500 !important;
	font-size: 16px !important;
}

.workspace {
	width: 100% !important;
	// background-color: #f7f6fb;
	height: 40px;
}

.workspaceName::placeholder {
	font-size: 16px;
	font-weight: 400;
	color: #82868a;
}

.modal-content {
	border-radius: 18px !important;
}
