@use "../../variables" as *;
header {
	height: $top-nav-height;
	z-index: 999 !important;
}
.navbar {
	padding-right: 40px;
	padding-left: 40px;
	// box-shadow: 0px 4px 12px 0px #356eff40;
	z-index: 9;
	background-color: #07163c !important;
}
// .nav-logo {
// 	background-color: #07163c;
// }

.dropShadow {
	box-shadow: 0 4px 12px 0 rgba(53, 110, 255, 0.2);
}
