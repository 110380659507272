@use "../../../../variables" as *;

.status {
	width: 100%;
	height: 32px;
	border-radius: 4px;
	background-color: white;
	margin-bottom: 8px;
	padding: 4px;
}

.color {
	width: 24px;
	height: 24px;
	margin-right: 8px;
	border-radius: 2px;
}

.name {
	font-size: 16px;
	font-weight: 400;
}

.icon > div {
	display: flex;
	margin-left: 10px;
	align-items: center;
	cursor: pointer;
}
