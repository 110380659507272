@use "../../../variables";
.workspace-container {
	margin: 0px 4px 40px 4px;
	gap: 10.92px;
}

.workspace-setting {
	padding-left: 14px;
	cursor: pointer;
	border: none;
	background: none;
	border-radius: 4px;
}

.workspace-setting h1 {
	font-size: 16px;
	line-height: 22px;
	text-align: left;
	font-weight: 400;
	font: variables.$primary-font;
}
