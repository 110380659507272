@import "../../../styles/theme-variables.scss";

.network-tab {
	padding: 0px !important;
}

.network-filters {
	padding: 12px;
}

.clickable > div {
	cursor: pointer;
	border-radius: 6px !important;
	@include themify($themes) {
		border: 1px solid themed("selectionColor");
	}
}

.selected {
	color: #356eff !important;
	border-radius: 6px !important;
	@include themify($themes) {
		background-color: themed("selectionColor");
	}
}

.metaNetworkTable {
	@include themify($themes) {
		background: themed("inputBackground") !important;
		color: themed("text") !important;
	}
}

.metaNetworkTableHead {
	@include themify($themes) {
		background-color: themed("inputBackground") !important;
		color: themed("text") !important;
		font-weight: 900;
	}
}

.console-input-border {
	border: 0.6px solid #356eff;
	@include themify($themes) {
		background-color: themed("inputBackground");
		color: themed("text") !important;
	}
	border-radius: 6px;
}

.console-input-border:focus {
	outline: none;
}

.console-input-border::placeholder {
	color: #356eff;
}

.tab-border {
	border: 0.6px solid #356eff;
	border-radius: 6px;
	font-size: 14px;
	font-weight: 400;
	padding: 5px 6px 5px 6px;
}

.network-filters-parent {
	width: 100%;
	height: 40px;
	border: 1px solid #5e5e5e;
	background-color: #1f1f1f;
	padding-left: 20px;
	padding-top: 6px;
	padding-bottom: 6px;
	display: flex;
	color: #fff;
}
.filter-container {
	background-color: #3c3c3c;
	color: #fff;
	width: 230px;
	height: 24px;
	border-radius: 12px;
	padding-left: 40px;
	outline: none !important;
	border: none;
	margin-right: 16px;
}
.filter-container::placeholder {
	color: #fff !important; /* White placeholder text */
}

.filter-svg {
	position: absolute;
	left: 20px;
	top: 0;
}
.filter-box {
	color: #fff;
	font-size: 14px;
	font-weight: 400;
	line-height: 16.41px;
	width: max-content;
	padding: 4px;
	border: 1px solid #e3e3e3;
	margin-left: 8px;
	cursor: pointer;
}
.filter-box.active {
	background-color: #796c3c !important;
}

.custom-checkbox .ant-checkbox-inner {
	background: #3b3b3b; /* Default background color */
	border: 1px solid #e3e3e3;
}
.custom-checkbox .checkbox-label {
	color: white; /* Color of the label text */
}
.ml-16 {
	margin-left: 16px;
}
.select-svg {
	margin-left: 4px;
}
.custom-table-container {
	height: 320px; /* Adjust as needed */
	overflow-y: auto;
	background-color: #333;
	// width: 100%;
	flex: 1;
}

.custom-table {
	width: 100%;
	border-collapse: collapse;
	color: #fff;
}

.custom-table thead th {
	background-color: #282828;
	color: #ddd;
	height: 50px;
	line-height: 50px;
	border: 1px solid #5e5e5e;
	padding-left: 20px;
}

.custom-table tbody td {
	border: 1px solid #5e5e5e;
	height: 35px;
	line-height: 35px;
	padding-left: 20px;
}

.custom-row-even {
	background-color: #1f1f1f;
}

.custom-row-odd {
	background-color: #282828;
}

.custom-table tbody td img {
	margin-right: 8px;
	vertical-align: middle;
}

/* src/CustomSelect.css */
.status-select-container {
	position: relative;
	width: 54px;
	margin-left: 16px;
}
.method-select-container {
	position: relative;
	width: 70px;
	margin-left: 16px;
}

.status-select {
	border-radius: 4px;

	cursor: pointer;
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.status-selected {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}

.status-options {
	display: block;
	border: 1px solid #ccc;
	border-radius: 4px;
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	background: #333;
	color: #fff;
	z-index: 1000;
}

.status-option {
	padding: 8px;
	cursor: pointer;
}

.clear-icon {
	margin-left: 8px;
	cursor: pointer;
}
.network-container {
	display: flex;
	position: relative;
}
.details-panel {
	position: absolute;
	top: 0;
	right: 0;
	width: 50%;
	height: auto;
	background-color: #222;
	color: #fff;
	border-left: 1px solid #5e5e5e;
	overflow-y: auto;
	transition: width 0.3s ease-in-out;

	z-index: 1000;
}
.draggable-handle {
	position: absolute;
	top: 0;
	left: 0;
	width: 5px; /* Make this small but wide enough for easy dragging */
	height: 100%;
	cursor: ew-resize;
	background-color: transparent; /* Invisible but still clickable */
}

.panel-header {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	background: #424242;
	color: "#fff";
	height: 35px;
	border-bottom: 1px solid #5e5e5e;
	padding-left: 8px;
	row-gap: 20px;
	column-gap: 20px;
}

.panel-content {
	padding-top: 20px;
	display: flex;
	flex-direction: column;
	height: 245px;
	overflow-y: auto;
}

.panel-content h4 {
	margin-top: 0;
}
.copy-network {
	background-color: #1f1f1f;
	color: #fff;
	display: flex;
	height: 40px;
	align-items: center;
	padding-left: 20px;
}

.general-info {
	border-top: 1px solid #444;
	border-bottom: 1px solid #444;
	padding-top: 8px;
	padding-bottom: 8px;
	padding-left: 8px;
}
.headers-container {
	margin-top: 1em;
}

.headers-title {
	font-weight: bold;
	margin-bottom: 0.5em;
	display: flex;
}

.headers-content {
	display: flex;
	flex-direction: column;
	padding-left: 32px;
}

.header-row {
	display: flex;
	justify-content: space-between;
	padding: 0.5em 0;
}

.header-name {
	font-weight: bold;
	flex: 1;
}

.header-value {
	flex: 2;
	word-wrap: break-word;
	padding-left: 10px;
}
.panel-tab {
	cursor: pointer;
	padding: 7px 20px;
	color: #e3e3e3;
}

.panel-tab.active {
	color: #d5bd6a; /* Golden color */
	border-bottom: 1px solid #c3a560; /* Golden border */
}
.info-row {
	display: flex;
	justify-content: flex-start;
	margin-top: 12px;
	align-items: center;
}

.info-label {
	width: 150px; /* Adjust this width to ensure proper alignment */
	font-weight: bold;
}

.info-value {
	flex: 1; /* This ensures that the value takes the remaining space */
	word-wrap: break-word;
}
.hljs {
	background-color: #222 !important;
	color: #dcdcdc;
}
.hljs-string {
	color: #ce9178; /* Color for strings (like in DevTools) */
}

.hljs-keyword {
	color: #569cd6; /* Keywords color (matches DevTools for keys) */
}

.hljs-number,
.hljs-literal {
	color: #b5cea8; /* Numbers, booleans */
}

.hljs-attribute {
	color: #9cdcfe; /* Attribute names (keys in JSON) */
}
.hljs-attr {
	color: #ce9178; /* Attribute names (keys in JSON) */
}

.hljs-comment {
	color: #6a9955; /* Comments if applicable */
}

.hljs-punctuation {
	color: #d4d4d4; /* Punctuation like braces, brackets */
}
