@use "../variables" as *;
.container {
	padding-top: $top-nav-height;
}

.page-container {
	padding: 24px 40px 0px 280px;
	height: calc(100vh - $top-nav-height);
	overflow-y: scroll;
	@media screen and (max-width: 767px) {
		padding: 24px 10px 0px 10px;
	}
}
