@use "../../variables" as *;
.container {
	width: 240px;
	position: fixed;
	height: calc(100vh - $top-nav-height);
}
.content {
	margin-top: 22px;
	padding: 0px 4px 0px 4px;
	max-height: 80%;
	color: $primary-font-color;
}
