@use "./variables" as *;
.App {
	// font-family: $primary-font;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

// .container {
// 	padding-top: 3.5rem;
// }

.page-container {
	// padding: 24px 40px 0px 280px;
	background-color: $background-color;
	height: 92vh;
}
