@import "../../../styles/theme-variables.scss";

.console-input-border {
	border: 0.3px solid #356eff;
	@include themify($themes) {
		background-color: themed("inputBackground");
		color: themed("text") !important;
	}
	border-radius: 6px;
}
.console-input-border:focus {
	outline: none;
}
.console-input-border::placeholder {
	@include themify($themes) {
		color: themed("tabHeader") !important;
	}
}

.console-select-border {
	border: 1px solid #7ea2ff;
	border-radius: 6px;
	width: 100%;
}

.console-types {
	cursor: pointer;
}

.console-types .active {
	border-radius: 6px;
	width: fit-content;
	@include themify($themes) {
		background-color: themed("selectionColor");
		color: themed("tabHeader") !important;
	}
}
.console-class__control {
	width: 100% !important;
	min-height: 32px !important;
	height: 32px !important;
	border: 1px solid #0b57d0 !important;
	border-radius: 7px !important;
	@include themify($themes) {
		background-color: themed("inputBackground");
		color: themed("text") !important;
	}
}

.console-class__single-value {
	@include themify($themes) {
		color: themed("text") !important;
	}
}

.console-class__menu-list {
	@include themify($themes) {
		background-color: themed("inputBackground") !important;
	}
}

#tabs-console {
	padding: 12px !important;
}

.tab-border {
	border: 0.6px solid #356eff;
	border-radius: 6px;
	font-size: 14px;
	font-weight: 400;
	padding: 5px 6px 5px 6px;
	width: fit-content;
	cursor: pointer;
}

.width-full {
	flex: 1;
	margin-right: 10px;
}

// new ui
.console-filters-parent {
	width: 100%;
	height: 40px;

	background-color: #1f1f1f;
	padding-left: 20px;
	padding-top: 6px;
	padding-bottom: 6px;
	display: flex;
	color: #fff;
	border: 1px solid #5e5e5e;
}
.console-filter-container {
	background-color: #3c3c3c;
	color: #fff;
	width: 829px;
	height: 24px;
	border-radius: 12px;
	padding-left: 40px;
	outline: none !important;
	border: none;
	margin-right: 16px;
}
.console-filter-container::placeholder {
	color: #fff; /* White placeholder text */
}

.filter-svg {
	position: absolute;
	left: 20px;
	top: 0;
}
.console-status-select-container {
	position: relative;
	width: 120px;
	margin-left: 16px;
	margin-right: 16px;
	top: -8px;
}

.console-status-select {
	border-radius: 4px;
	padding: 8px;
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
}

.console-status-selected {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.console-status-options {
	border: 1px solid #ccc;
	border-radius: 4px;
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	background: #333;
	color: #fff;
	z-index: 1000;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
	transition:
		opacity 0.3s ease,
		visibility 0.3s ease;
}

.console-status-option {
	padding: 8px;
	cursor: pointer;
	display: flex;
	align-items: center;
}

.console-tatus-option input {
	margin-right: 8px;
}

.console-status-option:hover {
	background: #444;
}
.error_console {
	display: flex;
	align-items: flex-start;
	margin-bottom: 10px;
	background-color: #4e3534; /* Dark red background similar to DevTools */
	color: #e8e6e3;
	padding: 10px;
	border-radius: 4px;
}

.error_icon {
	margin-right: 10px;
}

.error_icon img {
	vertical-align: middle;
}

.error_details {
	flex-grow: 1;
}

.error_value {
	margin-bottom: 10px; /* Space between lines */
	color: #fff; /* Reddish color similar to Chrome DevTools */
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #4e3534;
}

.error_url {
	color: #59b1e4; /* Link color similar to Chrome DevTools */
	cursor: pointer;
}

.dropdown_content {
	margin-top: 5px;
	background-color: #2d2d2d;
	padding: 10px;
	border-radius: 4px;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	z-index: 1;
}

.dropdown_content a {
	color: #59b1e4; /* Link color similar to Chrome DevTools */
	text-decoration: none;
}

.dropdown_content a:hover {
	background-color: #414141;
}
.console_event {
	display: flex;
	align-items: flex-start;
	margin-bottom: 10px;
	padding: 10px;
	border-radius: 4px;
	color: #e8e6e3;
}

.warning {
	background-color: #545439; /* Dark yellow background for warnings */
}

.event_icon {
	margin-right: 10px;
}

.event_icon img {
	min-width: 16px;
	min-height: 16px;
	vertical-align: middle;
}

.event_details {
	flex-grow: 1;
}

.event_value {
	margin-bottom: 10px; /* Space between lines */
	color: #f0eaaf; /* Yellow color for warnings */
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
}

.event_value a {
	color: #59b1e4; /* Link color similar to Chrome DevTools */
	text-decoration: none;
}

.event_value a:hover {
	text-decoration: underline;
}

.event_url {
	color: #59b1e4; /* Link color similar to Chrome DevTools */
	cursor: pointer;
	margin-left: 10px; /* Space between text and URL */
}

.dropdown_content {
	margin-top: 5px;
	background-color: #2d2d2d;
	padding: 10px;
	border-radius: 4px;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	z-index: 1;
}

.dropdown_content a {
	color: #59b1e4; /* Link color similar to Chrome DevTools */
	text-decoration: none;
}

.dropdown_content a:hover {
	background-color: #414141;
}
.log {
	background-color: #1e1e1e; /* Dark background for logs */
}
.log .event_value {
	color: #e8e6e3; /* Light color for logs */
}
