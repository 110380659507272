@use "../../variables";
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.1/font/bootstrap-icons.css");

.container {
	padding-top: 60px;
	min-height: 100vh;
}
.carousel {
	// align-items: unset !important;
	// padding-top: 131px;
	// min-height: 100vh;
	height: 100vh;
}
.signup-parent {
	min-height: 100vh;
	// padding-top: 131px;
}
.loginContent {
	max-width: 461px;
}
.loginForm label {
	width: 100%;
	font-size: 14px;
	line-height: 18px;
	text-align: left;
}
#name {
	background-color: white !important;
}
.loginHeading {
	font-size: 40px;
	font-weight: 600;
}

.input {
	height: 48px;
	background-color: white !important;
	border: 1px solid #d1d5e1 !important;
	border-radius: 8px;
	// margin-bottom: 16px;
	margin-top: 4px;
	padding: 0px 8px 0px 8px;
}

.loginButton {
	margin-top: 16px;
	border: none;
	height: 48px;
	color: white;
	background-color: variables.$secondary-color;
	border-radius: 8px;
	font-family: variables.$primary-font;
	font-size: 16px;
	font-weight: 600;
	margin-bottom: 10px;
}
.signupLink {
	font-family: variables.$primary-font;
	font-weight: 400;
	margin-bottom: 0px;
}
.signupLink h2 {
	font-size: 16px;
	margin-bottom: 0px;
}
.signupLink a {
	color: variables.$secondary-color;
	text-decoration: none;
}
.or {
	margin-top: 13px;
	margin-bottom: 19px;
}
.or span {
	width: 39px;
}
.or hr {
	width: 211px;
}
.oauthButtons {
	gap: 24px;
}
.oauthButton {
	font-weight: 500;
	font-family: variables.$primary-font;
	font-size: 16px;
	line-height: 24px;
	margin: 0;
	border: 1px solid variables.$secondary-color !important;
	p {
		margin-left: 12px;
	}
}
.loginButton:hover {
	background-color: variables.$secondary-color;
	color: white;
}

@media (max-width: 975px) {
	/* CSS rules for screens below 975px */
	.carousel {
		display: none !important;
		border: 1px solid red;
	}
	.signup-parent {
		width: 100% !important;
	}
}

.inner-addon-rp {
	position: relative;
}

.inner-addon-rp .icon {
	position: absolute;
	pointer-events: none;
	font-size: 24px;
	color: rgba(130, 134, 138, 0.85) !important;
}
