@import "../../../styles/theme-variables.scss";

.rowBlackStripe {
	background: #1f1f1f;
	color: white;
	height: 40px;
	padding-left: 1.25rem !important;
}

.rowWhiteStripe {
	background: #282828;
	color: white;
	height: 39px;
	padding-left: 1.25rem !important;
}

#tabs-general {
	padding: 0 !important;
}

.generalInfoTable {
	border-radius: 8px !important;
}
.border-top-golden {
	border-top: 1px solid;
	border-image-source: linear-gradient(90deg, #d5bd6a 0%, #6f6237 100%);
	border-image-slice: 1;
}
.url-hover:hover .copy-icon {
	display: block;
}
.dropdown-wrapper {
	position: relative;
	display: inline-block;
	font-family: "Inter", sans-serif;
	color: #f1f1f1;
}

.dropdown-label {
	display: flex;
	align-items: center;
	font-size: 14px;
	cursor: pointer;
}

.dropdown-icon {
	margin-left: 5px;
	transition: transform 0.3s ease;
	cursor: pointer;
}

.dropdown-icon.open {
	transform: rotate(180deg);
}

.dropdown-value {
	background-color: #2d3238;
	padding: 8px 10px;
	margin-top: 5px;
	border: 1px solid #353b40;
	border-radius: 5px;
	cursor: pointer;
	display: inline-block;
	width: auto;
	font-size: 14px;
}

.dropdown-list {
	position: absolute;
	top: 100%;
	left: 0;
	width: 250px;
	background-color: #2d3238;
	border: 1px solid #353b40;
	border-radius: 5px;
	margin-top: 5px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	z-index: 1000;
}

.dropdown-list li {
	padding: 10px;
	cursor: pointer;
	font-size: 14px;
	display: flex;
	align-items: center;
	color: #fff;
}
.ant-checkbox-wrapper {
	color: #fff !important;
}

.dropdown-icon-circle {
	width: 18px;
	height: 18px;
	border-radius: 50%;
	background-color: #343a40;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	margin-right: 10px;
	color: #50fa7b;
}
