@keyframes glow {
	0% {
		background-position: 200% 0;
	}

	100% {
		background-position: -200% 0;
	}
}

.skeleton {
	border-radius: 5px;
	background: linear-gradient(90deg, #d0d0d0 25%, #b0b0b0 50%, #d0d0d0 75%);
	background-size: 200% 100%;
	animation: glow 1.5s ease-in-out infinite;
}
