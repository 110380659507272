.notification-heading {
	// font-family: Poppins;
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
	letter-spacing: 0em;
	text-align: left;
}
.notification-preference-container {
	box-sizing: border-box;
	min-width: 100%; /* Adjusted for responsiveness */
	// max-width: 1192px; /* Ensures it doesn't exceed the original width */
	height: 329px;
	border-radius: 18px;
	background-color: #fff;
	margin-top: 1rem;
	overflow: hidden !important;
}
.notification-preference-header {
	padding-left: 1.5rem;
	padding-top: 1rem;
	padding-bottom: 0.5rem;
}

.notification-preference-thead {
	// font-family: Poppins;
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	letter-spacing: 0px;
	// text-align: left;
	color: #868e96;
}

.notification-preference-name {
	// font-family: Poppins;
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	letter-spacing: 0em;
	// text-align: left;
	color: #111111;
}

.input-checkbox {
	width: 19px;
	height: 19px;
}

.notification-preference-tbody {
	border-bottom: 2px solid #f7f6fb;
	padding-left: 1.5rem;
	padding-top: 0.2rem;
	padding-bottom: 0.5rem;
}

.save-btn {
	width: fit-content;
	height: 34px;

	padding: 12px;
	border-radius: 6px;
	gap: 4px;
	color: #fff;
	border: none;
	background-color: #356eff;
	margin-top: 2rem;
	// font-family: Poppins;
	font-size: 12px;
	font-weight: 600;
	line-height: 16px;
	letter-spacing: 0em;
	cursor: pointer;
	// text-align: left;
}

.checkbox-wrapper {
	padding: 3px;
	border-radius: 4px;
	cursor: pointer;
}
