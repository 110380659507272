@use '../../../variables';
.user-info {
	height: 40px;
	min-width: 164px;
	display: flex;
	justify-content: center;
	color: white;
	border-radius: 100px;
	padding: 8px 16px 8px 16px;
	background-color: variables.$secondary-color;
}
.topMenuLoginToUpload {
	border: none;
	color: white;
	font-family: variables.$primary-font;
}
.dropdown-item {
    min-width: 11rem;
    display: flex;
    align-items: center;
    margin: 0;
    // line-height: 1.4285714286
	cursor: pointer;
	min-height: 2rem;
}
.icon {
    width: 1.25rem;
    height: 1.25rem;
    font-size: 1.25rem;
    vertical-align: bottom;
    stroke-width: 1.5
}
