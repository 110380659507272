@use "../../variables";

h1,
p {
	font-family: variables.$primary-font;
}
.primaryHeading {
	// padding: 32px;
	padding-top: 0px;
	text-align: left;
}
.primaryHeading h1 {
	font-family: variables.$primary-font;
	font-size: 40px;
	font-weight: 700;
	text-align: left;
	line-height: 48px;
}

.carousel {
	position: relative;
	display: flex;
	flex-direction: column;
	// width: 628.5px;
	// height: 691px;
	width: 80%;
}
.dots {
	display: flex;
	bottom: -53px;
	align-items: center;
	justify-content: center;
	left: 50%;
	gap: 8px;
}
.dot {
	height: 16px;
	width: 16px;
	border-radius: 50%;
	background-color: #2d3751;
	border: 1px solid #2d3751;
}
.active {
	width: 32px;
	height: 18px;
	background-color: white;
	border: none;
	border-radius: 23px;
}
