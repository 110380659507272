:root {
	--black: #333333;
	--white: #f5f5f5;
	--background: var(--white);
	--foreground: var(--black);
	--transition: 0.5s ease;
}

.hidden {
	clip: rect(0 0 0 0);
	clip-path: inset(50%);
	height: 1px;
	overflow: hidden;
	position: absolute;
	white-space: nowrap;
	width: 1px;
}

.toggle-wrapper {
	width: 100px;
	display: block;
	margin: auto; /* Centering for demo */
}

.toggle {
	height: 40px;
	width: 91px;
	border-radius: 40px;
	padding: 4px;
	position: relative;
	transition: background var(--transition);
	cursor: pointer;
}

.toggle::before {
	content: "";
	display: block;
	height: 30px;
	width: 30px;
	border-radius: 30px;
	background: var(--background);
	position: absolute;
	z-index: 2;
	transform: translate(0);
	transition:
		transform var(--transition),
		background var(--transition);
}

.toggle.enabled::before {
	transform: translateX(51px);
}

.toggle input {
	opacity: 0;
	position: absolute;
	top: 0;
}

.toggle .icons {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 100%;
	margin: 0 5px;
}

.toggle .icons svg {
	fill: var(--background);
	height: 20px;
	width: 30px;
	z-index: 0;
}
