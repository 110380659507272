.ancester {
	display: flex;
	flex-direction: column;
	height: 100vh; /* Ensures it takes full viewport height */
	padding-left: 40px;
	// padding-right: 280px;
	width: 900px;
}
.parent-gptbuddy {
	display: flex;
	flex-direction: column;
	padding: 1rem;
	row-gap: 8px;
	overflow-y: auto;
	height: 350px;
	background-color: #282828;
}
.click-chat {
	display: flex;
	width: fit-content;
	max-width: calc(100% - 12px);
	border-radius: 24px;
	background-color: #282828;
	padding: 8px 16px;
	color: #fff;
	align-self: flex-end;
	word-break: break-word;
	white-space: break-spaces;
	box-shadow:
		0 0 0 1px #d5bd6a,
		0 0 0 1px #6f6237;

	cursor: pointer;
}

.typing-gif {
	display: flex;
	width: 3rem;
	border-radius: 16px;
	// background-color: #fff;
	padding: 8px;
	align-self: flex-start;
	// border: 1px solid rgb(223, 225, 224);
}
.search-box {
	display: flex;
	align-items: center;
	border: 1px solid #e3e3e3;
	padding: 5px 10px;
	background-color: #282828;
	margin-top: 8px;
	margin-bottom: 8px;
	flex-shrink: 0;
}

.search-box textarea {
	border: none;
	outline: none;
	flex: 1;
	padding: 5px 10px;
	font-size: 16px;
	background-color: transparent;
	resize: none;
	max-height: 48px;
	overflow-y: auto;
	color: white;
}

.search-box input::placeholder {
	color: #686868;
}

.search-button {
	background: none;
	border: none;
	cursor: pointer;
	padding: 5px;
}

.search-button svg {
	fill: #888;
}
