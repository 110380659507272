@use "../variables";
.custom-card-container {
	// width: 100%;
	min-width: 100%;
	height: 14.6rem;
	border-radius: 1.5rem;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	background-color: variables.$primary-font-color;
}
.custom-card-button {
	width: 100%;
	max-width: 10.5rem;
	height: 2rem;
	border-radius: 6px;
	gap: 4px;
	background-color: variables.$secondary-color;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 1.5rem;
	color: variables.$primary-font-color;
	cursor: pointer;
}

.custom-card-heading {
	font-size: 1.5rem;
	font-weight: 500;
	line-height: 2rem;
	letter-spacing: 0px;
	text-align: center;
}

.custom-card-description {
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.3rem;
	letter-spacing: 0px;
	text-align: center;
	width: 100%;
	max-width: 25.75rem;
	height: 2.5rem;
}

.custom-button-text {
	font-size: 0.75rem;
	font-weight: 600;
	margin-top: 3px;
	text-align: center;
}
