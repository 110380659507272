.skeleton-wrapper {
	padding: 80px 40px 30px 40px;
	width: 100%;
	height: 100vh;

	@media (max-width: 972px) {
		flex-direction: column;
	}

	@media (max-width: 500px) {
		padding: 80px 20px 30px 20px;
	}
}

.skeleton-cta-wrapper {
	justify-content: space-between;

	@media (max-width: 500px) {
		flex-direction: column;
		align-items: end;
	}
}

.workspace-skeleton {
	height: 32px;
	width: 200px;
	@media (max-width: 500px) {
		width: 100%;
		margin-bottom: 10px;
	}
}

.delete-skeleton {
	height: 32px;
	width: 32px;
}

.share-bug-skeleton {
	height: 32px;
	width: 150px;
}

.copy-link-skeleton {
	height: 32px;
	width: 100px;
}

.skelete-data-wrapper {
	width: 100%;
	margin-top: 16px;
	gap: 5px;
	justify-content: space-between;
	align-items: start;

	@media (max-width: 972px) {
		flex-direction: column;
	}
}

.left-skeleton {
	width: 56%;
	@media (max-width: 972px) {
		width: 100%;
	}
}

.annotation-skeleton {
	width: 100%;
	height: 32px;
}

.image-skeleton {
	height: 40vh;
	width: 100%;
}

.title-skeleton {
	height: 32px;
	margin-top: 8px;
	width: 100%;
}

.desc-skeleton {
	height: 100px;
	margin-top: 8px;
	width: 100%;
}

.comments-head-skeleton {
	height: 32px;
	margin-top: 8px;
	width: 50px;
}

.comments-count-skeleton {
	height: 32px;
	margin-top: 8px;
	width: 50px;
}

.comments-skeleton {
	height: 100px;
	margin-top: 8px;
	width: 100%;
}

.skeleton-title-wrapper {
	margin-top: 16px;
	width: 100%;
	gap: 10px;
}

.input-skeleton {
	width: 100%;
	height: 40px;
}

.ai-skeleton {
	width: 40px;
	height: 40px;
}

.skeleton-description {
	height: calc(100vh - 40vh - 260px);
	margin-top: 16px;
	width: 100%;
}

.skeleton-tab-wrapper {
	width: 54%;
	@media (max-width: 972px) {
		width: 100%;
	}
}

.tabs-skeleton {
	width: 100%;
	height: 32px;
}

.skeleton-tab-pane {
	width: 100%;
	margin-top: 8px;
	height: calc(100vh - 128px - 60px);
}
