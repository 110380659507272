@use "../../../variables" as *;
.container {
	width: fit-content;
	text-align: left;
	width: 80%;
	font-family: $primary-font;
}
.integration-section h1 {
	font-size: 16px;
	font-weight: 600;
}

.pmt-logo img {
	object-fit: contain;
	width: 20px;
	height: 20px;
}
.disabled {
	filter: grayscale(1);
	opacity: 0.5;
}
.enabled {
	opacity: 1;
}

.modal-settings-container{
	position: absolute;
	height : 257px;
	width : 224px ;
	left : 76%;
	bottom : 100%;
	background-color: #1e293b;
	z-index : 99999 !important;
    
	// color : #fff
}
.options-container {
    height: 220px;
    overflow-y: scroll;
	border-bottom: 1px solid gray;
	padding-top: 1rem;
	padding-left: 1rem;
	z-index : 99999 !important;
}

/* Style for the scrollbar itself */
.options-container::-webkit-scrollbar {
    width: 4px; /* Reduced width */
}

/* Style for the scrollbar track */
.options-container::-webkit-scrollbar-track {
    background: transparent; /* Optional: style as needed */
}

/* Style for the scrollbar thumb (the part you drag) */
.options-container::-webkit-scrollbar-thumb {
    background: #888; /* Optional: style as needed */
}

/* Style for the scrollbar thumb on hover */
.options-container::-webkit-scrollbar-thumb:hover {
    background: #555; /* Optional: style as needed */
}
.add-btn{
	background-color: #356EFF;
	display: inline-flex;
    align-items: center;
	justify-content: center;
    color: #fff;
    padding: 0 13px;
    border-radius: 6px;
    line-height: 23px;
    min-height: 28px;
	width : 51px;
    transition: all .1s ease-in-out;
	text-align: center;
}
.remove-btn{
	background-color: #ff6e64;;
	display: inline-flex;
    align-items: center;
	justify-content: center;
    color: #fff;
    padding: 0 13px;
    border-radius: 6px;
    line-height: 23px;
    min-height: 28px;
	width : 77px;
    transition: all .1s ease-in-out;
	margin-left: 5px;
	
}
.add-btn.disabled{
	filter: saturate(.8) brightness(.7);
	cursor: not-allowed;
}
.link-gray{
	
	display: inline-flex;
    align-items: center;
    cursor: pointer;
	font-size: 13px;
}
.btn-gray{

font-size: 14px;
}
.custom-dropdown-indicator{
    padding-bottom: 15px;
    padding-right: 10px;
}

.slack-options-container{
	position: absolute;
	height : 200px;
	width : 210px ;
	left : 78%;
	top:100%;
	z-index: 9999999;
}
.slack-heading{
	color :	#8c95a8;
font-size: 14px;
}
.slack-input-search{
	height: 32px;
	padding-left: 29px;
	padding-top: 0 !important;
	padding-bottom: 0 !important;
	color : #8c95a8 !important;
	background-color: #4b566b;
	border-radius: 6px;
	border-color: #4b566b;
	font-size: 14px;
	width: 100%;
	
}
.slack-input-search::placeholder {
	color: #8c95a8 !important; /* Placeholder text color */
	font-size: 14px;
	opacity: 1; /* For Firefox */
  }
  
  /* For WebKit browsers like Chrome and Safari */
  .slack-input-search::-webkit-input-placeholder {
	color: #8c95a8 !important;
  }
  .slack-input-search:focus {
	outline: none;
	
  }

  .individual-slack-channel{
	
	margin-bottom: 8px;
	padding-left: 10px;
	font-size: 14px;

  }
  .individual-slack-channel:hover{
	background-color:#8c95a8 ;
  }
  .slack-channels-list{
	max-height: 87px;
	height: 100%;
	overflow-x: hidden;
	overflow-y: auto;
	margin-top: 8px;
  }

  .do-not-notify{
	font-size: 14px;
  }
