@use "../../../variables";
.primary-button {
	background-color: transparent;
	width: 200px;
	color: variables.$primary-font-color;
	border: 1px solid variables.$secondary-color;
	padding: 4px, 24px, 4px, 24px;
	height: Hug (32px);
	gap: 4px;
	border-radius: 6px;
	font: variables.$primary-font;
	margin-top: 16px;
	font-weight: 600;
	font-size: 12px;
	line-height: 24px;
}
.primary-button:hover {
	background-color: variables.$secondary-color;
}
