@use "../../../variables";

.my-workspace-logo {
	width: 24px;
	height: 24px;
	min-width: 24px;
	max-width: 24px;
	left: 20px;
	top: 106px;
	border-radius: 50%;
	font-weight: 600;
	font-size: 16px;
	color: black;
	text-align: center;
}
.private-workspace-h1 {
	color: variables.$primary-font-color;
	font-size: 14px;
	margin-left: 4px;
	text-align: left;
	cursor: pointer;
}
.private-workspace-h1:hover {
	color: variables.$secondary-color;
}
.highlight {
	color: variables.$secondary-color;
}
