/* Custom styles for the logo */
.tb-logo {
    width: 100% !important
        /* Default width for larger screens */
}

/* Media query for mobile devices */
@media (max-width: 767px) {
    .tb-logo {
        width: 90px !important;
        margin-left: -29px;
        margin-right: 10px;
        /* 50% width for mobile devices */
    }
}