@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.1/font/bootstrap-icons.css");

.settings-field-height-rp {
	height: 56px;
}

.input-text-reset-password-rp {
	
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 22px;
	/* identical to box height */
	letter-spacing: 0.5px;
	color: #212529 !important;
}

.bg-color-ebf1fa {
	background-color: rgba(8, 80, 196, 0.08) !important;
}

.inner-addon-rp {
	position: relative;
}

.inner-addon-rp .icon {
	position: absolute;
	pointer-events: none;
	font-size: 24px;
	color: rgba(130, 134, 138, 0.85) !important;
}

.right-addon-rp .icon {
	right: 24px;
	top: 12px;
}

.setting-title-sub-rp {
	font-size: 14px !important;
}

.btn-primary-rp {
	background-color: #356eff !important;
}

.submit-button-rp {
	width: 260px;
	height: 56px;
	
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	line-height: 27px;
	letter-spacing: 1px;
}

.button-blue-rp {
	background: #356eff;
	transition: all 0.4s ease-in-out;
	box-sizing: border-box;
	cursor: pointer;
}

.button-blue-rp:hover {
	background: #0043af;
	box-shadow:
		rgb(8 80 196 / 50%) 0px 0px 2px 0px,
		rgb(48 64 91) 0px 12px 24px -4px;
}
