@import "../../styles/theme-variables.scss";
/* .css-13cymwt-control {
	background: rgba(8, 80, 196, 0.08) !important;
	height: 40px !important;
} */
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
	font-family: "Roboto", sans-serif;
}

:root {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	overflow-x: hidden;
}

.css-t3ipsp--control {
	height: 40px !important;
}

.css-1s2u09g-control {
	background: rgba(8, 80, 196, 0.08) !important;
	height: 40px !important;
}

.css-1pahdxg-control {
	height: 40px !important;
}

.addl-class__control {
	height: 40px !important;
}

/* * {
	font-family: Nunito, sans-serif, arial !important;
} */

.App {
	/* font-family: Nunito; */
	text-align: center;
}

.group-btn:focus {
	border: 1px solid #dee2e6 !important;
}

.btn:nth-child(1):focus {
	box-shadow: none !important;
	border: 1px solid #dee2e6 !important;
	border-right: none !important;
}

.btn:nth-child(2):focus {
	box-shadow: none !important;
	border: 1px solid #dee2e6 !important;
	border-right: none !important;
	border-left: none !important;
}

.btn:nth-child(3):focus {
	box-shadow: none !important;
	border: 1px solid #dee2e6 !important;
	border-left: none !important;
}

.error {
	/* font-family: 'Nunito'; */
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 19px;
	letter-spacing: 0.5px;
	color: #e74c3c;
}

.css-1s2u09g-control {
	background: rgba(8, 80, 196, 0.08) !important;
	height: 40px !important;
}

.css-1pahdxg-control {
	height: 40px !important;
}

.bug-details-page {
	position: relative;
}

.bug-details-header {
	position: sticky;
	top: 79px;
	background-color: white;
	z-index: 1;
}

.delete-svg-container {
	width: 33px;
	height: 32px;
	border: 1px solid #356eff;
	border-radius: 6px;
	cursor: pointer;
	@include themify($themes) {
		background: themed("metaBarBackground") !important;
	}
}

select.form-control {
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
}

.input {
	height: 40px;
	border-radius: 8px;
	margin-top: 12px;
	width: 100%;
	padding: 0px 8px;
	@include themify($themes) {
		background: themed("inputBackground") !important;
		border: 0.5px solid themed("border") !important;
		color: themed("text");
	}
}

.input:focus {
	outline: none; /* Remove the default focus outline */
}

@media (max-width: 768px) {
	.contact-card {
		padding: 0px !important;
		width: 100% !important;
		height: auto !important;
	}

	.tabcontent {
		padding: 0px;
	}

	.confirm-btn-project-step {
		flex: 0 0 auto;
		width: 100%;
	}

	.row {
		width: 100% !important;
	}
}

/* .css-1s2u09g-control {
	border: none !important;
	border-radius: 4px !important;
	background: rgba(24, 102, 225, 0.08) !important;
	height: 40px !important;
}

.css-1pahdxg-control {
	border-radius: 4px !important;
	height: 40px !important;
} */

/* .css-1s2u09g-control {
	border: none !important;
	border-top-right-radius: 4px !important;
	border-bottom-right-radius: 4px !important;
	border-top-left-radius: 0px !important;
	border-bottom-left-radius: 0px !important;
	background: rgba(24, 102, 225, 0.08) !important;
	height: 40px !important;
	
}

.css-1pahdxg-control {
	border-top-right-radius: 4px !important;
	border-bottom-right-radius: 4px !important;
	border-top-left-radius: 0px !important;
	border-bottom-left-radius: 0px !important;
	height: 40px !important;
	
	border-color: rgba(8, 80, 196, 0.08) !important;
	box-shadow: 0 0 0 3px rgba(8, 80, 196, 0.08) !important;
} */

.addl-class__control {
	width: 296px !important;
	height: 32px !important;

	border-radius: 7px !important;

	@include themify($themes) {
		background-color: themed("inputBackground") !important;
		border: 1px solid themed("border") !important;
	}
}

.addl-class__placeholder {
	@include themify($themes) {
		color: themed("text") !important;
	}
}

.addl-class__menu {
	@include themify($themes) {
		background-color: themed("inputBackground") !important;
	}
}

.createBugWrapper {
	height: 89%;
	@include themify($themes) {
		background: themed("bodyBackground") !important;
	}
}

.copyLink {
	height: 32px;

	border-radius: 6px;
	gap: 4px;
	background: #796c3c;
	color: #fff;

	cursor: pointer;
}

.copyLink-text {
	// font-family: Poppins;
	font-size: 12px;
	font-weight: 600;
	line-height: 16px;
	letter-spacing: 0em;
	// text-align: left;
}

.share-bug-container {
	width: 199px;
	height: 48px;
	border-radius: 12px;
	border: 1px solid #796c3c;
	background-color: #fff;
	color: #796c3c;
	margin-bottom: 10px;

	gap: 8px;
	padding: 12px;
	cursor: pointer;
	position: relative;
}
.share-bug-text {
	// font-family: Poppins;
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
	letter-spacing: 0em;
	// text-align: left;
}
.share-bug-text-small {
	// font-family: Poppins;
	font-size: 14px;
	font-weight: 400;
	line-height: 16px;
	letter-spacing: 0em;
	color: #000000;
	// text-align: left;
}

.share-bug-menu {
	background: white;
	position: absolute;
	top: 110%;
	z-index: 999999 !important;
	width: 214px;
	height: 292px;

	border-radius: 12px;
	border: 1px solid #796c3c;
	color: #000000;
}
.share-bug-menu div {
	cursor: pointer;
}
.share-bug-menu div:hover {
	background-color: #f6f6f6;
}

.share-bug-container-menus {
	width: 199px;
	height: 31px;

	background-color: #fff;
	color: #000000;

	gap: 4px;
	cursor: pointer;
	padding-left: 10px;
	border-radius: 12px;
}
.share-via-email-menus {
	background-color: #fff;
	color: #000000;

	gap: 4px;
	cursor: pointer;
}
.line-share-bug {
	width: 199px;
	height: 0.5px;
	border-radius: 100px;
	background: #b3b5b7;
	margin: auto;
}
.ai-container {
	width: 40px;
	height: 40px;
	background-color: #7ea2ff;
	border-radius: 5px;
	margin-top: 11px;
}
.beta {
	background-color: #dbeafe;
	color: #4462bf;
	border-radius: 20px;
	padding-left: 5px;
	padding-right: 5px;
}
.connected-pmt-circle {
	width: 10px;
	height: 10px;
	border-radius: 10px;
	background-color: #2fb344;
}
.do-not-create-pmt-circle {
	width: 10px;
	height: 10px;
	border-radius: 10px;
	background-color: orange;
}

.right-side-tb {
	position: -webkit-sticky !important; /* for browser compatibility */
	position: sticky !important;
	top: 70px !important;
}

.right-side-tb-meta {
	position: -webkit-sticky !important; /* for browser compatibility */
	position: sticky !important;
	top: 114px !important;
}

.right-side-tb-logged-in {
	position: -webkit-sticky !important; /* for browser compatibility */
	position: sticky !important;
	top: 111px !important;
}

.right-side-tb-meta-logged-in {
	position: -webkit-sticky !important; /* for browser compatibility */
	position: sticky !important;
	top: 155px !important;
}

.ͼ1 .cm-content {
	overflow: hidden !important;
}
.action-btn-sharebug {
	border: none;
	width: 103px;
	background-color: #fff;
}
body.modal-open {
	overflow: unset !important;
}

.share-link {
	position: absolute;
	right: 5px;
	top: 2px;
	bottom: 0px;
	width: 91px;
	background: #356eff;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.share-link-icon {
	position: absolute;
	left: 5px;
	top: 8px;
	bottom: 0px;
	height: 20px;
	width: 20px;
}

.full-space {
	flex: 1;
	height: 48px;
}

.line-share-bug-email {
	border: 1px solid #d9dbde;
	height: 0.5px;
	width: 100%;
	margin-bottom: 20px;
}

// new UI

.border-golden {
	border: 4px solid;

	border-image-source: linear-gradient(90deg, #d5bd6a 0%, #6f6237 100%);
	border-image-slice: 1;
}

.bug-details-label {
	font-size: 20px;
	color: #000000;
	font-weight: 500;
	line-height: 23px;
}

.title-input {
	width: 100%;
	height: 48px;
	padding: 16px;
	border-radius: 4px;
	border: 1px solid #796c3c;
	background: #796c3c26;
	color: #000000;
	outline: none;
}
.title-input::placeholder {
	color: #8b8e8d;
}

.description-input {
	width: 100%;
	border: 1px solid #796c3c;
	background: #796c3c26;
	padding: 16px;
	color: #000000;
	height: 100%;
	outline: none;
	resize: none;
	border-radius: 4px;
}
.svg-container {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	pointer-events: none; /* Allow clicks to pass through */
	opacity: 0.5; /* Slight transparency for subtle effect */
}

.description-svg {
	max-width: 100%; /* Ensure it doesn't overflow */
	max-height: 100%; /* Ensure it doesn't overflow */
	transition: opacity 0.3s ease; /* Smooth transition for hiding */
}
.description-input::placeholder {
	color: #8b8e8d;
}

.draggable-slider {
	position: absolute;
	border: 1px solid;

	border-image-source: linear-gradient(90deg, #d5bd6a 0%, #6f6237 100%);
	border-image-slice: 1;

	bottom: 0;
	width: 100%;
	height: 50px; /* Initial height */
	max-height: 50%;
	transition: height 0.3s ease;
	z-index: 9999999;
}

.slider-btn {
	position: absolute;
	top: -16px; /* Adjust this value as needed to position the button above the arrow */
	left: 50%; /* Center horizontally */
	transform: translateX(-50%);
	cursor: ns-resize;
}

.slider-content {
	background: #fff;
	overflow: hidden;
	height: 100%;
}

/* Tab styling */
.tabs {
	list-style: none;
	display: inline-flex;
	padding: 0;
	margin: 0;
}

.tabssss {
	padding-top: 8px;
	padding-bottom: 8px;
	padding-left: 12px;
	padding-right: 12px;
	cursor: pointer;
	text-align: center;
	border: 1px solid;
	display: flex;
	justify-content: center;
	align-items: center;

	border-image-source: linear-gradient(90deg, #d5bd6a 0%, #6f6237 100%);
	border-image-slice: 1;

	white-space: nowrap; /* Prevent text from wrapping */
	width: 132px;
	height: 50px;
	max-width: 150px; /* Adjust max-width as needed */
	overflow: hidden; /* Hide overflow */
	text-overflow: ellipsis; /* Show ellipsis for overflowing text */
}

.tabssss.active {
	background-image: linear-gradient(
		90deg,
		rgba(213, 189, 106, 0.3) 0%,
		rgba(111, 98, 55, 0.3) 100%
	);
}

.tabssss.active .tabs-label-text {
	color: #796c3c;
	font-weight: 500;
}

.arrow {
	position: absolute;
	top: 14px;
	right: 24px;
	cursor: pointer;
}
.tabs-label {
	display: flex;

	column-gap: 4px;
}
.tabs-label-text {
	color: #6d6d6d;

	font-size: 16px;
	font-weight: 400;
	line-height: 18.75px;
}
.tab-content {
	background-color: #282828;
}
.deletebtn-div {
	width: 48px;
	max-width: 48px;
	height: 48px;
	border-radius: 12px;
	border: 1px solid #796c3c;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}
.sharebtn-div {
	width: max-content;
	height: 48px;
	border-radius: 12px;
	border: 1px solid #796c3c;
	padding: 12px;
	display: flex;
	cursor: pointer;
	flex-grow: 1;
	flex-shrink: 1;
}
.createbtn-div {
	width: max-content;
	height: 48px;
	border-radius: 12px;

	padding: 12px;
	display: flex;
	cursor: pointer;
	background-color: #796c3c;
}
.create-label {
	color: #fff;
	font-weight: 500;
	line-height: 24px;
	margin-left: 8px;
	font-family: "Poppins", sans-serif;
	font-size: 16px;
}
.share-label {
	color: #796c3c;
	font-weight: 500;
	line-height: 24px;
	margin-left: 8px;
	font-family: "Poppins", sans-serif;
	font-size: 16px;
}
.sendtojira-div {
	width: max-content;
	height: 48px;
	border-radius: 12px;
	background-color: #796c3c;
	padding: 12px;
	display: flex;
	cursor: pointer;
	flex-grow: 1;
	flex-shrink: 1;
	min-width: 138px;
	white-space: nowrap;
}
.send-to-jira-label {
	color: #ffffff;
	font-weight: 500;
	line-height: 24px;
	margin-left: 8px;
	font-family: "Poppins", sans-serif;
	font-size: 13px;
	overflow: hidden;
	// text-overflow: ellipsis; /* Adds ellipsis (…) if text overflows */
	white-space: nowrap;
}
.modal-backdrop.show {
	opacity: 0.9 !important; /* Increase opacity for a darker backdrop */
	background-color: rgba(0, 0, 0, 0.9); /* Darker black background */
}

.comments-ui-parent {
	width: 100%;
	height: 106px;
	background-color: #ebe9e2;
}

.comments-upper-ui {
	padding-left: 12px;
	padding-right: 12px;
	padding-top: 10px;
	padding-bottom: 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.comments-parts {
	display: flex;
	justify-content: center;
	column-gap: 8px;
	align-items: center;
}
.comments-parts-2 {
	display: flex;
	justify-content: center;
	column-gap: 12px;
	align-items: center;
}
.comments-label {
	color: #000000;
	font-size: 20px;
	font-weight: 500;
	line-height: 23px;
}
.comments-number {
	color: #796c3c;
	font-size: 16px;
	font-weight: 400;
	line-height: 18.75px;
}

.comments-lower-ui {
	width: 100%;
	height: 48px;
	position: relative;
}
.send-comment-input {
	border: 1px solid #796c3c;
	border-radius: 4px;
	width: 100%;
	height: 100%;
	padding-left: 16px;
	padding-right: 70px;
	background: #796c3c26;
	color: black;
	outline: none !important;
}

.send-comment-input::placeholder {
	color: #8b8e8d !important;
}

.send-comment-btn {
	right: 16px;
	position: absolute;
	top: 14px;
	cursor: pointer;
}
.attachment-comment-btn {
	right: 46px;
	position: absolute;
	top: 10px;
	cursor: pointer;
}
#file-input {
	display: none;
}

// topnav
header {
	height: 64px !important;
}

.topMenuLoginToUpload {
	width: 155px;
	height: 40px;
	top: 10px;
	left: 1317px;
	padding: 8px, 24px, 8px, 24px;
	border-radius: 100px;
	gap: 10px;
	font-family: "Poppins", sans-serif !important;
	z-index: 99 !important;
}

html,
body {
	@include themify($themes) {
		background-color: themed("bodyBackground") !important;
	}
}

.topMenuLogo {
	height: "100%";
}

.topMenuBulb {
	width: 24px;
	height: 24px;
	cursor: pointer;
	margin-right: 16px;
}

.pointer {
	cursor: pointer !important;
}

.topMenuFont {
	font-family: "Poppins", sans-serif !important;
	font-size: 14px;
	color: white !important;
}

.topMenuUserDropdownButton {
	background: #0b57d0;
	width: 155px;
	height: 40px;
	padding: 8px, 24px, 8px, 24px;
	border-radius: 100px;
	font-size: 12px;
	font-weight: 600;
	border: none;
	color: white;
}

// new ui

.header-css {
	height: 64px !important;
	width: 100%;
	background-color: #000000 !important;
	display: flex;
	justify-content: space-between !important;
	align-items: center;
	padding-left: 40px;
	padding-right: 40px;
	padding-top: 14px !important;
	padding-bottom: 22px !important;
}
.tagline {
	background: linear-gradient(90deg, #d5bd69 0%, #ffffff 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	font-family: "Roboto", sans-serif;
	font-weight: 500;
	font-size: 14px;
	line-height: 16.41px;
	margin-top: 8px;
}

.dropdown-wrapper {
	position: relative;
	display: inline-block;
	font-family: "Inter", sans-serif;
	color: #f1f1f1;
}

.top-dropdown-label {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 20px;
	cursor: pointer;
}

.dropdown-icon {
	margin-left: 5px;
	transition: transform 0.3s ease;
	cursor: pointer;
}

.dropdown-icon.open {
	transform: rotate(180deg);
}

.top-dropdown-value {
	background-color: #2d3238;
	padding: 8px 10px;
	margin-top: 5px;
	border: 1px solid #353b40;
	border-radius: 5px;
	cursor: pointer;
	display: inline-block;
	width: 80px;
	font-size: 20px;
	color: "#fff";
	position: absolute;
}
