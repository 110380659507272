@use "../../variables" as *;

.page-heading {
	font-size: 16px;
	font-weight: 500;
	color: $secondary-color;
	text-align: left;
	margin-bottom: 12px;
}

.page-heading-span {
	font-weight: 600;
}

.tab-container {
	background: white;
	height: 32px;
	border-radius: 2px;
}

.tab-item {
	height: 100%;
	display: flex;
	align-items: center;
}

.tab-item.active {
	border-bottom: 3px solid $secondary-color;
}

.tab-item > p {
	// font-size: 16px;
	font-weight: 500;
	text-align: center;
	margin: 0;
}

.cursor-pointer {
	cursor: pointer;
}

.tab-content {
	margin-top: 24px;
	height: max-content;
	overflow-y: scroll;
}
