@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.1/font/bootstrap-icons.css");

.navbar-brand {
	
	font-style: normal;
	font-weight: 800;
	font-size: 32px;
	line-height: 44px;
	/* identical to box height */
	letter-spacing: 3px;
	color: #356eff !important;
}

.navbar-brand-image {
	height: 40px;
}

.input-text {
	
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 22px;
	/* identical to box height */
	letter-spacing: 0.5px;
	color: #212529 !important;
}

.settings-field-height {
	height: 56px;
}

.input-text {
	
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 22px;
	/* identical to box height */
	letter-spacing: 0.5px;
	color: #212529 !important;
}

.bg-color-ebf1fa {
	background-color: rgba(8, 80, 196, 0.08) !important;
}

.btn-primary {
	background-color: #356eff !important;
}

.submit-button {
	width: 260px;
	height: 56px;
	
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	line-height: 27px;
	letter-spacing: 1px;
}

.button-blue {
	background: #356eff;
	transition: all 0.4s ease-in-out;
	box-sizing: border-box;
	cursor: pointer;
}

.button-blue:hover {
	background: #0043af;
	box-shadow:
		rgb(8 80 196 / 50%) 0px 0px 2px 0px,
		rgb(48 64 91) 0px 12px 24px -4px;
}
