@import "../../../styles/theme-variables.scss";

@import "../../../styles/theme-variables.scss";

.cm-theme {
	max-height: 64vh;
	min-height: 64vh;
}
.cm-editor {
	min-height: 64vh;
}
/* .cm-gutters{
    max-height: 60vh;
}
.cm-gutter{
    max-height:  60vh !important;
}
.cm-scroller{
    max-height: 60vh;
} */
.ͼ1 .cm-content {
	flex-shrink: 1;
	overflow: scroll;
}
.root-wrapper {
	min-width: max-content;
	min-height: max-content;
	color: #fff;
}

.css-1439o8t-control {
	min-height: 32px !important;
	height: 32px !important;
	border: 1px solid #0b57d0 !important;
	border-radius: 7px !important;
	@include themify($themes) {
		background-color: themed("inputBackground");
		color: themed("text") !important;
	}
}

.css-1dimb5e-singleValue {
	@include themify($themes) {
		color: themed("text") !important;
	}
}

.cm-activeLine,
.cm-editor,
.cm-gutters {
	@include themify($themes) {
		background: #282828 !important;
	}
}
.ͼ1,
.ͼ2,
.ͼ3,
.ͼ4,
.ͼ5,
.ͼ6,
.ͼ7,
.ͼ8,
.ͼ9,
.ͼ10,
.ͼ11,
.ͼ12,
.ͼ13,
.ͼ14,
.ͼ15,
.ͼ16,
.ͼ17,
.ͼ18,
.ͼ19,
.ͼ20,
.ͼ21,
.ͼ22,
.ͼ23,
.ͼ24,
.ͼ25,
.ͼ26,
.ͼ27,
.ͼ28,
.ͼ29,
.ͼ30,
.ͼ31,
.ͼ32,
.ͼ33,
.ͼ34,
.ͼ35,
.ͼ36,
.ͼ37,
.ͼ38,
.ͼ39,
.ͼ40,
.ͼ41,
.ͼ42,
.ͼ43,
.ͼ44,
.ͼ45,
.ͼ46,
.ͼ47,
.ͼ48,
.ͼ49,
.ͼ50,
.ͼ51,
.ͼ52,
.ͼ53,
.ͼ54,
.ͼ55,
.ͼ56,
.ͼ57,
.ͼ58,
.ͼ59,
.ͼ60,
.ͼ61,
.ͼ62,
.ͼ63,
.ͼ64,
.ͼ65,
.ͼ66,
.ͼ67,
.ͼ68,
.ͼ69,
.ͼ70,
.ͼ71,
.ͼ72,
.ͼ73,
.ͼ74,
.ͼ75,
.ͼ76,
.ͼ77,
.ͼ78,
.ͼ79,
.ͼ80,
.ͼ81,
.ͼ82,
.ͼ83,
.ͼ84,
.ͼ85,
.ͼ86,
.ͼ87,
.ͼ88,
.ͼ89,
.ͼ90,
.ͼ91,
.ͼ92,
.ͼ93,
.ͼ94,
.ͼ95,
.ͼ96,
.ͼ97,
.ͼ98,
.ͼ99 {
	color: #fff !important;
}

.ͼ1 .cm-lineNumbers .cm-gutterElement {
	width: 20px; /* Ensures enough width for up to 3 digits */
	// height: 20px !important;
	background: #796c3c;
	border-radius: 50%;
	color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 10px; /* Smaller font size to fit digits */
	line-height: 1;
	margin-bottom: 8px;
}
.ͼ1 .cm-line {
	margin-top: 8px;
	margin-bottom: 8px;
}

.cm-content {
	color: white; /* Code text color */
}

.cm-gutters {
	border-right: 1px solid #796c3c !important;
}

.copyLink {
	height: 48px !important;

	border-radius: 12px !important;
	gap: 4px;
	background: #796c3c;
	color: #fff;

	cursor: pointer;
}

.copyLink-text {
	// font-family: Poppins;
	font-size: 16px !important;
	font-weight: 500 !important;
	line-height: 16px !important;
	letter-spacing: 0em;
	// text-align: left;
}

.table-parent {
	max-height: 100%;

	overflow: auto;
	@media (max-width: 768px) {
		// Adjust the max-width value based on your breakpoint for small screens
		max-height: 50vh;
		padding: 8px;
	}
}
.code-parent {
	width: 100%;
	justify-content: space-between;
	@media (max-width: 768px) {
		// Adjust the max-width value based on your breakpoint for small screens
		align-items: flex-start;
		row-gap: 10px;
	}
}
