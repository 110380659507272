@use "../../../variables";
.domain-access-text {
	// font-family: variables.$primary-font;
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
	letter-spacing: 0em;
	text-align: left;
	color: #111111;
}

.domain-access-text-table {
	// font-family: variables.$primary-font;
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
	letter-spacing: 0em;
	text-align: left;
	color: #111111;
	width: 100%;
}

.domain-access-description {
	font-family: variables.$primary-font;
	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
	letter-spacing: 0px;
	text-align: left;
	color: #b6b0cd;
}

.domain-access-input {
	width: 508px;
	height: 32px;

	border-radius: 4px;
	background: #ffffff;
	font-family: variables.$primary-font;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: 0em;
	text-align: left;
}
.delete-domain-container {
	width: 200px;
	height: 32px;

	border-radius: 6px;
	border: 1px solid #ff4135;
	gap: 10px;
}

.delete-domain-text {
	font-family: variables.$primary-font;
	font-size: 12px;
	font-weight: 600;
	line-height: 16px;
	letter-spacing: 0em;
	text-align: left;
	color: #ff4135;
	padding-top: 2px;
}
.invite-member-input {
	width: 350px;
	height: 32px;
	border-radius: 4px;
	background-color: #fff;
	font-family: variables.$primary-font;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: 0em;
	text-align: left;
}
.invite-member-select {
	width: 142px;
	max-height: 32px !important;

	border-radius: 6px;
}

.invite-member-btn {
	width: 142px;
	height: 32px;
	border-radius: 6px;
	border: 1px solid #356eff;
	color: #356eff;
	background-color: #fff;

	gap: 10px;
	cursor: pointer;
}
.invite-member-text {
	color: #356eff;
}

.add-more-members-container {
	width: 508px;
}
.add-more-members-text {
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: 0px;
	text-align: left;
	margin-top: 1px;
}

.manage-members-container {
	width: 1192px;
	height: 329px;
	border-radius: 18px;
	background: #fefefe;
}

.search-member-input {
	width: 274px;
	height: 32px;
	border-radius: 6px;
	background: #f7f6fb;
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	letter-spacing: 0px;
	text-align: left;
	color: #82868a;
}

.filter-by-role {
	width: 200px;
	height: 32px;
	border-radius: 6px;
}

.custom-dropdown-indicator {
	padding-bottom: 4px;
	padding-right: 10px;
}
.table-header {
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	letter-spacing: 0px;
	text-align: left;
	color: #868e96;
}

.table-content {
	border-top: 2px solid #f7f6fb;
}

.table-content-text {
	// font-family: Poppins;
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	letter-spacing: 0em;
	text-align: left;
	color: #111111;
}
.icon > div {
	display: flex;

	align-items: center;
	cursor: pointer;
}

.error-email-text {
	text-align: left !important;
}

.allowed-domain-container {
	width: 508px;
}
.allowed-domain-input {
	width: 508px;
	height: 2rem;
	background: #fff;
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	letter-spacing: 0px;
	text-align: left;
}
.warning-text {
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	letter-spacing: 0px;
	text-align: left;
}
.add-domain-btn {
	width: 142px;
	height: 2rem;
	border-radius: 6px;
	border: 1px solid #356eff;
	background-color: #fff;
	color: #356eff;
}
.allowed-domain-text {
	font-size: 14px;
	font-weight: 500;
	line-height: 18px;
	letter-spacing: 0px;
	text-align: left;
}

:global {
	.css-13cymwt-control {
		max-height: 32px !important;
		min-height: 32px !important;
	}

	.css-1u9des2-indicatorSeparator {
		display: none;
	}
}
