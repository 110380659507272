@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100&family=Montserrat:wght@100&family=Poppins:display=swap");

$primary-color: #07163c;
$secondary-color: #356eff;
$primary-font: "Poppins", sans-serif;
$primary-font-color: white;
$workspace-heading-color: #7ea2fe;
$background-color: #f7f6fb;
$secondary-font-color: #b6b0cd;
$workspace-bugsearch-color: #82868a;
$workspace-bugcontainer-background-color: #fefefe;
$bug-reporter-avatar-bg-color: #ff3e3e;
$bug-link-bg-color: #a5adf5;
$top-nav-height: 60px;
