.shared-workspace {
	width: 232px;
	margin: 16px auto auto auto;
	padding: 12px 20px 12px 16px;
	border-radius: 4px;
}

.heading {
	margin-bottom: 12px;
}
.heading h1 {
	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
}

.workspace-pill {
	gap: 8px;
	width: 200px;
	max-height: 50vh;
	overflow-y: scroll;
}
.workspace-pill::-webkit-scrollbar {
	width: 1px;
	border-radius: 10px;
	scrollbar-width: thin;
	opacity: 0;
	transition: opacity 0.3s;
}

.workspace-pill::-webkit-scrollbar-thumb {
	border-radius: 5px;
}

.workspace-pill:hover::-webkit-scrollbar {
	opacity: 1;
}
