#tb-rrweb {
  width: 100%;
  /* max-width: 800px; */
  margin: 0 auto;
}

/*  
.rr-player,
.rr-player__frame,
.replayer-wrapper,
iframe,
.rr-controller {
  width: 100% !important;
  max-width: 100% !important;
 
} */

.rr-timeline {
  width: 100% !important;
}

.rr-controller__btns.svelte-19ke1iv button.active.svelte-19ke1iv,
.rr-progress__handler.svelte-19ke1iv.svelte-19ke1iv,
.switch.svelte-9brlez input[type='checkbox'].svelte-9brlez:checked+label.svelte-9brlez:before {
  background: #796C3C !important;
}

/* Reset scaling and adjust transform origin */
/* .replayer-wrapper {
  transform: scale(100%) translate(-50%, -50%) !important;
  transform-origin: center !important;
} */

.rr-player {
  border-radius: 8px !important;
  /* width: 100% !important; */
  border: 1px solid #796C3C !important;
}

.rr-player__frame {
  border-radius: 8px 8px 0px 0px !important;
}


@media (max-width: 768px) {
  #tb-rrweb {
    max-width: 100%;
  }

  .rr-player {
    width: 100% !important;
  }

  .rr-player__frame {
    width: 100% !important;
  }
}

.replayer-wrapper>canvas,
.replayer-wrapper>iframe {
  /* width: 100% !important; */
}