@use "../../variables";
.workspace-container {
	// margin-left: 17.5rem;
	margin-top: 32px;
}

.workspace-main {
	// margin-left: 17.5rem;
	margin-top: 2rem;
}
.workspace-main-heading {
	text-align: left;
	padding-left: 0.8rem;

	font-size: 20px;
	font-weight: 500;
	line-height: 30px;
	letter-spacing: 0em;
}
.workspace-header {
	max-width: 100%;
}
.workspace-bugs-container {
	width: 100%;
	max-height: 100%;
	border-radius: 18px;
	background: variables.$workspace-bugcontainer-background-color;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.workspace-bug-search {
	// max-width: 15rem;
	flex: 1;
	height: 2rem;
	border-radius: 6px;
	// background: variables.$background-color;
	font-size: 13px;
	// font-weight: 400;
	line-height: 20px;
	letter-spacing: 0px;
	text-align: left;
	margin-right: 20px;
	// color: variables.$workspace-bugsearch-color;
}

.switch-view-container {
	width: 7.5rem;
	height: 2rem;
	padding: 2px;
	border-radius: 100px;
	background: variables.$workspace-heading-color;
	margin-left: 2.5rem;
}

.table-button {
	padding-right: 9px;
	padding-left: 15px;
}

.grid-button {
	padding-left: 9px;
	padding-right: 15px;
}
.highlighted {
	border-radius: 100px;
	width: 57px;
	background-color: variables.$secondary-color;
}
.switch-icon {
	height: 21px;
	width: 34px;
}
