@use "../../../variables" as *;

.workspace-name {
	font-size: 16px;
	font-weight: 500;
	text-align: left;
	margin-bottom: 8px;
}
.workspace-name-form {
	margin-bottom: 1rem;
}

.workspace-name-input {
	width: 60%;
	padding-left: 12px;
	// padding : 0 !important;
	border: none;
	border-radius: 4px;
	// margin-bottom: 16px;
	//font weight 400
	//font size 16
	//top bottom padding 4
}

.workspace-ai {
	font-weight: 500;
	font-size: 16px;
	margin-bottom: 10px;
}

.workspace-ai > span {
	font-weight: 400;
	font-size: 12px;
	color: $secondary-font-color;
}

.workspace-ai-status {
	width: 188px;
	height: 32px;
	border-radius: 100px;
	background-color: white;
}

.workspace-ai-status > span {
	width: 92px;
	border-radius: 100px;
	font-weight: 50;
	font-size: 16px;
	height: 100%;
	cursor: pointer;
}

.workspace-ai-status span:first-child.active {
	background: #2ccf29;
	color: white;
}

.workspace-ai-status span:last-child.active {
	background: #ff4135;
	color: white;
}

.workspace-bug-status {
	margin-top: 16px;
	margin-bottom: 8px;
	font-weight: 500;
	font-size: 16px;
}

.workspace-bug-status > span {
	font-weight: 400;
	font-size: 12px;
	color: $secondary-font-color;
}

.workspace-bug-status-list {
	list-style: none;
	width: 60%;
	padding: 0;
	margin: 0;
}

.workspace-bug-status-list > li {
	width: 100%;
}

.add-status-btn {
	width: 100%;
	height: 32px;
	border-radius: 4px;
	background-color: white;
	margin-bottom: 8px;
	padding: 9px;
	border: none;
	text-align: left;
	font-weight: 400;
}

.add-status-btn > span {
	margin-left: 12px;
	font-weight: 400;
	font-size: 16px;
}

.workspace-delete-btn {
	margin-top: 40px;
	height: 32px;
	border-radius: 4px;
	background-color: white;
	margin-bottom: 8px;
	padding: 4px;
	color: #ff4135;
	border: 1px solid #ff4135;
	text-align: left;
	font-weight: 600;
	font-size: 12px;
	cursor: pointer;

	button {
		width: 100%;
		color: #ff4135;
		border: none;
		background-color: white;
	}

	svg {
		stroke: #ff4135;
	}
}

.workspace-delete-btn:hover {
	background-color: #ff4135;
	color: white;

	button {
		background-color: #ff4135;
		color: white;
	}

	svg {
		stroke: white !important;
	}
}
