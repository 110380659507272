@import '../../../../styles/theme-variables.scss';

.color-picker-container {
    position: relative;
}

.hidden-input {
    position: absolute;
    top: -9999px;
    left: -9999px;
}

.custom-color-picker {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #000;
    /* Default color, you can customize this */
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
}

.upper-canvas {
    border-radius: 8px !important;
    border: none !important
}

.lower-canvas {
    border-radius: 8px !important;
}

.annotationContainer{
    @include themify($themes) {
        border: 1px solid themed('border') !important;
        background: themed('inputBackground') !important;
    }
}

///
/// 

label {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  cursor: pointer;
}


#style2 {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: transparent;
    width: 22px;
    height: 22px;
    border: none;
    cursor: pointer;
  }
  #style2::-webkit-color-swatch {
    border-radius: 50%;
    @include themify($themes) {
    border: 1px solid themed("text") ;
    }
  }
  #style2::-moz-color-swatch {
    border-radius: 50%;
    @include themify($themes) {
    border: 1px solid themed("text") !important ;
    }
  }