$theme-map: null;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100&family=Montserrat:wght@100&family=Poppins:display=swap");

$primary-color: #07163c;
$secondary-color: #356eff;
$primary-font: "Poppins", sans-serif;
$workspace-heading-color: #7ea2fe;
$background-color: #f7f6fb;
$secondary-font-color: #b6b0cd;

$themes: (
	light: (
		primary-color: white,
		navbar-color: #ffffff,
		navbar-color-bugdetails: #07163c,
		option-hover-color: #7ea2fe,
		primary-font-color: black,
		secondary-font-color: white,
		ternay-font-color: #356eff,
		workspace-logo-color: #d5e1fe,
		selected-workspace-color: #7ea2fe,
		integration-popup-container-color: #fff,
		workspace-navbar-bg: white,
		white-black-color: black,
		body-background: #f7f6fb,
		navbar-shadow: 0px 4px 12px 0px #356eff40,
		side-nav: #07163c,
		table-search: #f7f6fb,
		table-search-placeholder: #82868a,
		table-search-color: #82868a,
		table-background: #fefefe,
		input-color: black,
		card-background: white,
		black-stripe: #f2f2f2,
		white-stripe: #ffffff,
		tab-header: #3326cb,
		selection-color: #c5d5fe,
		modal-input-bg: #f7f6fb,
		badge-heading-color: #7ea2fe,
		meta-tab-background: white,
		border: #356eff,
		input-background-settings: white,
		table-header-text: #868e96,
		table-border-color: #f7f6fb,
		checkbox-border-color: #868e96,
		card-box-shadow: 0px 4px 4px 0px #7ea2fe85,
		primary-font-color-invert: white,
		invert-image: none,
		table-hover-color: #eaf0ff,
	),
	dark: (
		primary-color: #07163c,
		navbar-color: #1c2a4c,
		navbar-color-bugdetails: #1c2a4c,
		option-hover-color: #7ea2fe52,
		primary-font-color: white,
		white-black-color: white,
		body-background: #0e131f,
		secondary-font-color: #82868a,
		ternay-font-color: white,
		workspace-logo-color: white,
		selected-workspace-color: #2f3d62,
		integration-popup-container-color: #2f3d62,
		workspace-navbar-bg: #1c2a4c,
		navbar-shadow: 0px 4px 4px 0px #131b2f85,
		side-nav: #090d17,
		input-background: #090d17,
		table-search: #1c2a4c,
		table-search-placeholder: #82868a,
		table-background: #090d17,
		input-color: white,
		card-background: #090d17,
		black-stripe: #090d17,
		white-stripe: #1c2a4c,
		tab-header: #356eff,
		selection-color: #1c2a4c,
		modal-input-bg: #1c2a4c,
		badge-heading-color: #356eff,
		meta-tab-background: #090d17,
		border: #356eff,
		// input-background-settings: #090d17,
		input-background-settings: #1c2a4c,
		table-header-text: #868e96,
		table-border-color: #2d364e,
		checkbox-border-color: #7ea2fe,
		card-box-shadow: 0 4px 4px 0 rgba(19, 27, 47, 0.5),
		primary-font-color-invert: black,
		invert-image: invert(1),
		table-hover-color: #1c2a4c,
	),
);

@mixin themify($themes) {
	@each $theme, $map in $themes {
		.theme-#{$theme} & {
			$theme-map: () !global;
			@each $key, $submap in $map {
				$value: map-get(map-get($themes, $theme), "#{$key}");
				$theme-map: map-merge(
					$theme-map,
					(
						$key: $value,
					)
				) !global;
			}
			@content;
			$theme-map: null !global;
		}
	}
}

@function themed($key) {
	@return map-get($theme-map, $key);
}
input,
.input {
	@include themify($themes) {
		// background-color: themed("input-background") !important;
		// color: themed("input-color") !important;
		// border: none !important;
	}
}
.table-search {
	@include themify($themes) {
		background-color: themed("table-search") !important;
		color: themed("input-color") !important;
		border: none !important;
	}
}
.table-search::placeholder {
	@include themify($themes) {
		color: #82868a !important;
	}
}
.nav-logo {
	@include themify($themes) {
		background-color: themed("navbar-color") !important;
	}
	.logo-fill {
		@include themify($themes) {
			fill: themed("ternay-font-color") !important;
		}
	}
}
.cards {
	@include themify($themes) {
		background-color: themed("input-background") !important;
	}
}
input::placeholder {
	@include themify($themes) {
		color: themed("input-color") !important;
	}
}
.page-container {
	@include themify($themes) {
		background-color: themed("body-background") !important;
	}
}
.sidenav-background {
	@include themify($themes) {
		// color: themed("white-black-color") !important;
		background-color: themed("side-nav") !important;
		z-index: 999;
	}
}

.badge-heading-color {
	@include themify($themes) {
		color: themed("badge-heading-color") !important;
	}
}
.primary-background {
	@include themify($themes) {
		color: themed("white-black-color") !important;

		background-color: themed("primary-color") !important;
	}
}
.navbar-background {
	@include themify($themes) {
		background-color: themed("navbar-color") !important;
	}
	img {
		@include themify($themes) {
			background: themed("navbar-color") !important;
		}
	}
	.nav-logo > svg {
		@include themify($themes) {
			background: themed("navbar-color") !important;
		}
	}
}

.navbar-background-bugdetails {
	@include themify($themes) {
		background-color: themed("navbar-color-bugdetails") !important;
	}
	img {
		@include themify($themes) {
			background: themed("navbar-color-bugdetails") !important;
		}
	}
	.nav-logo > svg {
		@include themify($themes) {
			background: themed("navbar-color-bugdetails") !important;
		}
	}
}

.navbar-shadow {
	@include themify($themes) {
		box-shadow: themed("navbar-shadow") !important;
	}
}

.primary-font-color {
	@include themify($themes) {
		color: themed("primary-font-color") !important;
	}
}
.hoverable-option:hover {
	@include themify($themes) {
		background-color: themed("option-hover-color") !important;
	}
}
.workspace-logo {
	@include themify($themes) {
		background: themed("workspace-logo-color") !important;
	}
}
.selected-workspace {
	@include themify($themes) {
		background: themed("selected-workspace-color") !important;
	}
}

.integration-popup-container {
	@include themify($themes) {
		background: themed("integration-popup-container-color") !important;
	}
}

.table-background {
	@include themify($themes) {
		background: themed("table-background") !important;
	}
}

.secondary-font-color-placeholder::placeholder {
	@include themify($themes) {
		color: themed("secondary-font-color") !important;
	}
}
.header-button-background {
	@include themify($themes) {
		background: themed("input-background");
	}
}

// .workspace-navbar {
// 	@include themify($themes) {
// 		background: themed("workspace-navbar-bg") !important;
// 		color: themed("white-black-color") !important;
// 	}
// }
.css-1nmdiq5-menu {
	// position: unset !important;
	z-index: 9999;
}
.css-b62m3t-container {
	width: 100%;
}

.createBugWrapper {
	@include themify($themes) {
		background: themed("body-background") !important;
		color: themed("primary-font-color");
	}
}
.section-title {
	@include themify($themes) {
		color: themed("primary-font-color");
	}
}

.section-title.active {
	@include themify($themes) {
		color: themed("tab-header");
		border-bottom: 2px solid themed("tab-header");
	}
	font-weight: 500;
}

.section-border {
	border: 0.5px solid $secondary-color;
}
.plan-header {
	@include themify($themes) {
		background-color: themed("navbar-color-bugdetails");
	}
}

.section-data {
	border: 0.5px solid $secondary-color;
	@include themify($themes) {
		background-color: themed("card-background");
	}
}

.bug-details-description {
	@include themify($themes) {
		background: themed("card-background");
		color: themed("primary-font-color");
	}
}

.font-color {
	@include themify($themes) {
		color: themed("primary-font-color") !important;
	}
}

.console-input-border {
	@include themify($themes) {
		background-color: themed("input-background");
		color: themed("primary-font-color") !important;
	}
}
.console-input-border:focus {
	outline: none;
}
.console-input-border::placeholder {
	@include themify($themes) {
		color: themed("tab-header") !important;
	}
}

.console-types {
	@include themify($themes) {
		color: themed("primary-font-color") !important;
	}
}

.console-types .active {
	border-radius: 6px;
	width: fit-content;
	@include themify($themes) {
		background-color: themed("selection-color");
		color: themed("tab-header") !important;
	}
}

.clickable {
	@include themify($themes) {
		color: themed("primary-font-color");
		// border: 1px solid themed("selection-color");
	}
}

.selected {
	color: #356eff !important;
	border-radius: 6px;
	@include themify($themes) {
		background-color: themed("selection-color");
	}
}

.metaNetworkTable {
	@include themify($themes) {
		background: themed("input-background") !important;
		color: themed("primary-font-color") !important;
	}
}

.metaNetworkTableHead {
	@include themify($themes) {
		background-color: themed("input-background") !important;
		color: themed("primary-font-color") !important;
		font-weight: 900;
	}
}

.console-input-border {
	border: 0.6px solid #356eff;
	@include themify($themes) {
		background-color: themed("input-background");
		color: themed("primary-font-color") !important;
	}
	border-radius: 6px;
}

.css-1439o8t-control {
	min-height: 32px !important;
	height: 32px !important;
	border: 1px solid #0b57d0 !important;
	border-radius: 7px !important;
	@include themify($themes) {
		background-color: themed("input-background");
		color: themed("primary-font-color") !important;
	}
}

.css-1dimb5e-singleValue {
	@include themify($themes) {
		color: themed("primary-font-color") !important;
	}
}

.cm-activeLine,
.cm-editor,
.cm-gutters {
	@include themify($themes) {
		background: themed("input-background") !important;
	}
}

.cm-gutters {
	@include themify($themes) {
		border-right: 1px solid themed("border") !important;
	}
}

.comments {
	@include themify($themes) {
		background: themed("input-background") !important;
	}
}
.plan-card-details {
	@include themify($themes) {
		background-color: themed("input-background");
	}
}

.text-color,
.plan-text,
.notification-text {
	@include themify($themes) {
		color: themed("white-black-color");
	}
}

.input-color-settings {
	@include themify($themes) {
		color: themed("primary-font-color") !important;
		background-color: themed("input-background-settings") !important;
	}
	border: none;
}

.input-color-settings:focus {
	@include themify($themes) {
		border: 1px solid themed("border") !important;
	}
	box-shadow: none !important;
}

.input-color-settings::placeholder {
	@include themify($themes) {
		color: #b6b0cd !important;
	}
}

.tb-modal-heading {
	@include themify($themes) {
		color: themed("white-black-color") !important;
	}
}

.workspace {
	@include themify($themes) {
		background-color: themed("modal-input-bg") !important;
	}
}
.css-qbdosj-Input input {
	background-color: transparent !important;
}
.sidenav-select .css-1nmdiq5-menu {
	position: unset !important;
	z-index: 99999999;
	min-width: 100%;
}
.section-border {
	@include themify($themes) {
		background-color: themed("meta-tab-background");
		border: 0.5px solid themed("border");
	}
}

.section-data-meta-tabs {
	@include themify($themes) {
		background-color: themed("input-background");
		border: 0.5px solid themed("border");
	}
}

.meta-tab-pane {
	border-radius: 8px;
	@include themify($themes) {
		background-color: themed("meta-tab-background");
	}
}

.add-status-btn-color {
	@include themify($themes) {
		color: themed("white-black-color");
	}
}

.add-status-btn-color,
.delete-workspace-btn-color {
	@include themify($themes) {
		background-color: themed("navbar-color") !important;
	}
}

.delete-workspace-btn-color:hover {
	background-color: #ff4135 !important;

	button {
		background-color: #ff4135 !important;
		color: white !important;
	}
}

.table-background {
	@include themify($themes) {
		background: themed("card-background") !important;
		color: themed("white-black-color") !important;
	}
}

.border {
	@include themify($themes) {
		border: 0.5px solid themed("border") !important;
	}
}

.table-header-text {
	@include themify($themes) {
		color: themed("table-header-text") !important;
	}
}

.table-border {
	@include themify($themes) {
		border-bottom: 1.5px solid themed("table-border-color") !important;
	}
}

.table-input {
	@include themify($themes) {
		background: themed("table-search") !important;
		color: themed("white-black-color") !important;
		border: none !important;
	}
}

.table-input::placeholder {
	color: #82868a;
}

.checkbox-border {
	@include themify($themes) {
		border: 1px solid themed("checkbox-border-color") !important;
	}
}

.border-checked {
	border: 1px solid #356eff;
}

.checkbox-border::after {
	// @include themify($themes) {
	background: $secondary-color !important;
	accent-color: white !important;
	color: white !important;
	// }
}

.card-box-shadow {
	@include themify($themes) {
		box-shadow: themed("card-box-shadow") !important;
	}
}

.image-invert {
	@include themify($themes) {
		filter: themed("invert-image");
	}
}

.clickable {
	cursor: pointer;

	&:hover {
		@include themify($themes) {
			background: themed("table-hover-color") !important;
		}
	}
}

body {
	@include themify($themes) {
		background: themed("body-background") !important;
		background-color: themed("body-background") !important;
	}
}
