@use "../../variables" as *;

:root {
	font-family: $primary-font !important;
}
.table {
	border-radius: 4px !important;
	text-align: left;
	min-width: 600px !important;

	th span {
		color: #868e96;
		cursor: default;
		font-weight: 400;
	}
	.sortable {
		cursor: pointer;
	}
	tr {
		height: 40px;
		vertical-align: middle;
	}
}

.table-footer {
	font-size: 14px;
	display: flex;
	justify-content: space-between;
}
.nav-button {
	border: none;
	background-color: transparent;
}

.page-active {
	background: #356eff;
	color: white;
	border-radius: 4px;
}
